import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/base/shield-icon.svg";

import SupportIconImage from "images/base/support-icon.svg";
import ShieldIconImage from "images/base/shield-icon.svg";
import CustomizeIconImage from "images/base/customize-icon.svg";
import FastIconImage from "images/base/fast-icon.svg";
import ReliableIconImage from "images/base/reliable-icon.svg";
import SimpleIconImage from "images/base/simple-icon.svg";

const Container = tw.div`relative bg-primary-500 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
    ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
    ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
    .imageContainer {
        ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
        img {
            ${tw`w-6 h-6`}
        }
    }

    .textContainer {
        ${tw`mt-6`}
    }

    .title {
        ${tw`tracking-wider font-bold text-xl leading-none`}
    }

    .description {
        ${tw`mt-2 font-normal text-gray-400 leading-snug`}
    }
`;

export default ({
    cards = null,
    heading = "Notre proposition",
    subheading = "",
    description = "Une application de suivi simple et innovante.",
}) => {
    /*
     * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  If a key for a particular card is not provided, a default value is used
     */

    const defaultCards = [
        {
            imageSrc: ShieldIconImage,
            title: "Sécurisée",
            description:
                "Une gestion des rôles sur mesûre et une confidentialité de vos données",
        },
        {
            imageSrc: SupportIconImage,
            title: "Support à l'écoute",
            description:
                "Nous sommes en permanence à l'écoute de vos problèmes et de vos suggestions",
        },
        {
            imageSrc: CustomizeIconImage,
            title: "Adaptable",
            description:
                "Paramètres ajustables pour répondre à vos besoins spécifiques",
        },
        {
            imageSrc: ReliableIconImage,
            title: "Fiable",
            description: "Une technologie robuste et testée",
        },
        {
            imageSrc: FastIconImage,
            title: "Rapide",
            description:
                "Une installation instantanée pour démarrer rapidement",
        },
        {
            imageSrc: SimpleIconImage,
            title: "Simple",
            description:
                "Une interface intuitive facilitant la prise en main pour tous",
        },
    ];

    if (!cards) cards = defaultCards;

    return (
        <Container>
            <ThreeColumnContainer>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <VerticalSpacer />
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
                            <span className="imageContainer">
                                <img
                                    src={card.imageSrc || defaultCardImage}
                                    alt=""
                                />
                            </span>
                            <span className="textContainer">
                                <span className="title">
                                    {card.title || "Fully Secure"}
                                </span>
                                <p className="description">
                                    {card.description ||
                                        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                                </p>
                            </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
        </Container>
    );
};
