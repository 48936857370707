import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import {
    SectionHeading as Heading,
    Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/base/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/base/svg-decorator-blob-8.svg";
import pp from "../../images/anonyme.jpg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-wrap flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
    ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
    subheading = "Avis",
    heading = "Ils nous font confiance",
    testimonials = [
        {
            imageSrc: pp,
            quote: "Ingénieur tour de france en graduate programme chez Colas",
            customerName: "Baptiste PINEAU",
        },
        {
            imageSrc: pp,
            quote: "Conducteur de travaux chez GTM Bâtiment",
            customerName: "Antoine DRIEU",
        },
        {
            imageSrc: pp,
            quote: "Assistant conducteur de travaux chez Vinci construction",
            customerName: "Ugo DA SILVA",
        },
        {
            imageSrc: pp,
            quote: "Responsable gros oeuvre chez Bouygues Bâtiment",
            customerName: "Xavier ELIAS",
        },
        {
            imageSrc: pp,
            quote: "Gérant de THD Bâtiment (PME)",
            customerName: "Thierry DEHOUSSE",
        },
    ],
}) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                <Testimonials>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialContainer key={index}>
                            <Testimonial>
                                <Image src={testimonial.imageSrc} />
                                <Quote>{testimonial.quote}</Quote>
                                <CustomerName>
                                    {testimonial.customerName}
                                </CustomerName>
                            </Testimonial>
                        </TestimonialContainer>
                    ))}
                </Testimonials>
            </ContentWithPaddingXl>

            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
