import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useAnimation, motion } from "framer-motion";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import ai from "../../images/ai.jpg";
import stockage from "../../images/stockage.jpg";
import pointage from "../../images/pointage.jpg";
import badge from "../../images/badge2.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
    ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
    svg {
        ${tw`w-6 h-6`}
    }
`;
const PrevButton = tw(ControlButton)`hover:scale-120`;
const NextButton = tw(ControlButton)`hover:scale-120`;

const CardSlider = styled(Slider)`
    ${tw`mt-16`}
    .slick-slide {
        ${tw`h-auto flex justify-center mb-1`}
    }
    .slick-track {
        ${tw`h-auto flex `}
    }
    .slick-list {
        ${tw`overflow-visible`}
    }
`;

const Card = tw(
    motion.div
)`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;

// const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none duration-300 hover:scale-120`;
// const MotionCard = motion.custom(Card);

const CardImage = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
    ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
    svg {
        ${tw`w-6 h-6 text-yellow-500 fill-current`}
    }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
    ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
    svg {
        ${tw`w-3 h-3`}
    }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(
    PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;

export default ({ reference }) => {
    /* Change this according to your needs */
    const cards = [
        {
            imageSrc: stockage,
            title: "Stockage",
            description:
                "Nous vous proposons un suivi en temps réel des stocks et des zones de stockage. Vous serez alertés lorsque les niveaux seront  bas et aurez un suivi détaillé de la consommation de chaque collaborateur. Découvrez comment, en cliquant sur Plus d'informations.",
            locationText: "Rome, Italy",
            pricingText: "USD 39/Day",
            rating: "4.8",
        },
        {
            imageSrc: pointage,
            title: "Pointage",
            description:
                "Imaginez ne plus avoir besoin de pointer vos collaborateurs et qu'en plus, grâce à une interface simple et interactive, l'analyse ne devienne qu'un coup d'œil?",
            locationText: "Arizona, RAK",
            pricingText: "USD 99/Day",
            rating: 4.5,
        },
        {
            imageSrc: ai,
            title: "IA",
            description:
                "Un bras droit avec une mémoire illimitée qui peut vous faire des analyses et des prédictions vous intéresse? Avec OSMOSE, nous vous donnons accès aux dernières technologies que nous avons adapté au monde du BTP.",
            locationText: "Ibiza, Spain",
            pricingText: "USD 50/Day",
            rating: 4.9,
        },
        {
            imageSrc: badge,
            title: "Badges",
            description:
                "Création des badges: Vous en avez marre de remplir les fiches Excel pour la création des badges permettant l'accès au chantier? Réalisez la liste des ouvriers agréés en un seul scanne de la carte BTP.",
            locationText: "Rome, Italy",
            pricingText: "USD 39/Day",
            rating: "4.8",
        },
    ];

    const isSmall = useMediaQuery({ query: "(max-width: 900px)" });
    const isMedium =
        useMediaQuery({ query: "(max-width: 1280px)" }) && !isSmall;

    // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
    const [sliderRef, setSliderRef] = useState(null);
    const [CardIndex, setCardIndex] = useState(0);
    const sliderSettings = {
        beforeChange: (old, newI) => {
            CardAnimation(newI);
        },
        afterChange: (index) => {
            setCardIndex(index);
        },
        arrows: false,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },

            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    function getSlideToShow() {
        if (isSmall) return 1;
        if (isMedium) return 2;
        return 3;
    }

    function showCard(index, middle) {
        // console.log(index, CardIndex);
        let overflow = middle + getSlideToShow() - cards.length - 1;
        if (index <= middle + getSlideToShow() - 1 && index >= middle) {
            // console.log(true);
            return 1;
        }
        if (index <= overflow) {
            // console.log(true);
            return 1;
        }
        // console.log(false);
        return 0;
    }

    const cardsControls = Array.from({ length: 4 }, () => useAnimation());
    const CardAnimation = async (k) => {
        for (let i = 0; i < cardsControls.length; i++) {
            cardsControls[i].start({
                opacity: showCard(i, k),
                transition: { duration: 0.5 },
            });
        }
    };
    useEffect(() => {
        CardAnimation(CardIndex);
    });

    return (
        <Container ref={reference}>
            <Content>
                <HeadingWithControl>
                    <Heading>Nos offres</Heading>
                    <Controls>
                        <PrevButton onClick={sliderRef?.slickPrev}>
                            <ChevronLeftIcon />
                        </PrevButton>
                        <NextButton onClick={sliderRef?.slickNext}>
                            <ChevronRightIcon />
                        </NextButton>
                    </Controls>
                </HeadingWithControl>
                <CardSlider ref={setSliderRef} {...sliderSettings}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            animate={cardsControls[index]}
                            whileHover={{ scale: 1.2 }}
                        >
                            <CardImage imageSrc={card.imageSrc} />
                            <TextInfo>
                                <TitleReviewContainer>
                                    <Title>{card.title}</Title>
                                    {/* <RatingsInfo>
                                        <StarIcon />
                                        <Rating>{card.rating}</Rating>
                                    </RatingsInfo> */}
                                </TitleReviewContainer>
                                {/* <SecondaryInfoContainer>
                                    <IconWithText>
                                        <IconContainer>
                                            <LocationIcon />
                                        </IconContainer>
                                        <Text>{card.locationText}</Text>
                                    </IconWithText>
                                    <IconWithText>
                                        <IconContainer>
                                            <PriceIcon />
                                        </IconContainer>
                                        <Text>{card.pricingText}</Text>
                                    </IconWithText>
                                </SecondaryInfoContainer> */}
                                <Description>{card.description}</Description>
                            </TextInfo>
                            <PrimaryButton>Informations</PrimaryButton>
                        </Card>
                    ))}
                </CardSlider>
            </Content>
        </Container>
    );
};
