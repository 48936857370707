import React, { createContext, useRef } from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage";

import Hero from "../components/hero/TwoColumnWithVideo.js";
// import Hero from "../components/hero/TwoColumnWithInput.js";
// import Hero from "../components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "../components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "../components/hero/FullWidthWithImage.js";
// import Hero from "../components/hero/BackgroundAsImage.js";
// import Hero from "../components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "../components/features/ThreeColSimple.js";
// import Features from "../components/features/ThreeColWithSideImage.js";
import Features from "../components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "../components/features/VerticalWithAlternateImageAndText.js";
// import Features from "../components/features/DashedBorderSixFeatures";
// import MainFeature from "../components/features/TwoColWithButton.js";
// import MainFeature from "../components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "../components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "../components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "../components/features/TwoColWithSteps.js";
// import FeatureStats from "../components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "../components/pricing/ThreePlans.js";
// import Pricing from "../components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Pricing from "../components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "../components/cards/ThreeColSlider.js";
import SliderCard from "../components/cards/OwnThreeColSlider.js";
// import TrendingCard from "../components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "../components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "../components/cards/TabCardGrid.js";
import Profile from "../components/cards/ProfileThreeColGrid.js";

// import Blog from "../components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "../components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "../components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "../components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "../components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "../components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "../components/testimonials/TwoColumnWithImageAndRating.js";
import Testimonial from "../components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "../components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "../components/faqs/SimpleWithSideImage.js";
import FAQ from "../components/faqs/SingleCol.js";
// import FAQ from "../components/faqs/TwoColumnPrimaryBackground.js";

import ContactUsForm from "../components/forms/SimpleContactUs.js";
// import FindUsWithMap from "../components/forms/FindUsWithMap.js";
// import ContactUsForm from "../components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "../components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "../components/cta/GetStarted.js";
// import GetStarted from "../components/cta/GetStartedLight.js";
// import DownloadApp from "../components/cta/DownloadApp.js";

// import Footer from "../components/footers/SimpleFiveColumn.js";
// import Footer from "../components/footers/FiveColumnWithInputForm.js";
// import Footer from "../components/footers/FiveColumnWithBackground.js";
// import Footer from "../components/footers/FiveColumnDark.js";
import Footer from "../components/footers/MiniCenteredFooter.js";

// To scroll to a section, you have to put in the component:
// - import { ScrollContext } from "helpers/ScrollContext.js";
// - import { useContext } from "react";
// Then use the reference given here in arguments:
// export default ({ reference }) => {
//     let refs = useContext(ScrollContext);
// Then:
// onClick={() => {
//     refs.scrollToSection("MainFeature");
// }}

export function Main() {
    const sections = {
        Hero: useRef(null),
        SliderCard: useRef(null),
        Pricing: useRef(null),
        Testimonial: useRef(null),
        Features: useRef(null),
        Profile: useRef(null),
        FAQ: useRef(null),
        ContactUsForm: useRef(null),
    };

    const scrollToSection = (section) => {
        if (sections[section] && sections[section].current) {
            sections[section].current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <ScrollContext.Provider value={{ scrollToSection }}>
            <AnimationRevealPage>
                <Hero reference={sections["Hero"]} />
                <SliderCard reference={sections["SliderCard"]} />
                <Pricing reference={sections["Pricing"]} />
                <Testimonial reference={sections["Testimonial"]} />
                <Features reference={sections["Features"]} />
                <Profile reference={sections["Profile"]} />
                <FAQ reference={sections["FAQ"]} />
                <ContactUsForm reference={sections["ContactUsForm"]} />
                <Footer />
            </AnimationRevealPage>
        </ScrollContext.Provider>
    );
}
export const ScrollContext = createContext();
