import React from "react";
import AOS from "aos";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "helpers/ScrollToTop";
import { Main } from "pages/Page";

// To navigate between the pages:
// import { Navigate, useNavigate } from "react-router-dom";
// const navigate = useNavigate();
// const goHome = () => {
//     navigate("/");
// };

export default function App() {
    AOS.init();
    return (
        <>
            <BrowserRouter>
                <GlobalStyles />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Main />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}
