import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
    SectionHeading,
    Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/base/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/base/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/base/github-icon.svg";
import paul from "../../images/team-3.jpg";
import alexis from "../../images/team-1.jpg";
import mehdi from "../../images/team-2.jpg";
import pp from "../../images/anonyme.jpg";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
    ${(props) =>
        css`
            background-image: url("${props.imageSrc}");
        `}
    ${tw`w-64 h-64 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .school {
    ${tw` text-xs text-gray-600`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const CardLinks = styled.div`
    ${tw`mt-6 flex`}
    .link {
        ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
        .icon {
            ${tw`fill-current w-6 h-6`}
        }
    }
`;

export default ({
    heading = "Notre équipe",
    subheading = "Nos talents",
    description = "Notre équipe est composée de jeunes étudiants provenants de grandes écoles d'ingénieurs, soit spécialisés dans le batiment, soit dans l'informatique.",
    cards = [
        {
            imageSrc: alexis,
            position: "CFO & VP Sales",
            name: "Alexis GALLAND",
            school: "ESTP",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: mehdi,
            position: "Strategic Director",
            name: "Mehdi OUERFELLI",
            school: "Arts & Métiers",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: paul,
            position: "CTO",
            name: "Paul GAUTHIER",
            school: "ENSIMAG",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: pp,
            position: "CEO",
            name: "Kinan GHANEM",
            school: "ESTP",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: pp,
            position: "Développeur",
            name: "Antonin LITSHGY",
            school: "ENSEEIHT",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
        {
            imageSrc: pp,
            position: "Développeur",
            name: "Yan GROUZELLE",
            school: "EPITA",
            links: [
                {
                    url: "https://twitter.com",
                    icon: TwitterIcon,
                },
                {
                    url: "https://linkedin.com",
                    icon: LinkedinIcon,
                },
                {
                    url: "https://github.com",
                    icon: GithubIcon,
                },
            ],
        },
    ],
}) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                <HeadingContainer>
                    {subheading && <Subheading>{subheading}</Subheading>}
                    {heading && <Heading>{heading}</Heading>}
                    {description && <Description>{description}</Description>}
                </HeadingContainer>
                <Cards>
                    {cards.map((card, index) => (
                        <Card key={index}>
                            <CardImage imageSrc={card.imageSrc} />
                            <CardContent>
                                <span className="position">
                                    {card.position}
                                </span>
                                <span className="name">{card.name}</span>
                                <span className="school">{card.school}</span>
                                <CardLinks>
                                    {card.links.map((link, linkIndex) => (
                                        <a
                                            key={linkIndex}
                                            className="link"
                                            href={link.url}
                                        >
                                            <link.icon className="icon" />
                                        </a>
                                    ))}
                                </CardLinks>
                            </CardContent>
                        </Card>
                    ))}
                </Cards>
            </ContentWithPaddingXl>
        </Container>
    );
};
